import dayjs from 'dayjs'

export default {
  methods: {
    // 格式化时间
    dateFormat(row, column) {
      var date = row[column.property];
      return date ? dayjs(date * 1000).format("YYYY-MM-DD HH:mm:ss") : "无";
    }
  }
};
