<template>
  <div class="form">
    <!-- 表单 -->
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.roleName" clearable>
          <template slot="prepend">角色名称</template>
        </el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="search.status" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button class="add-btn" @click="$emit('add-role')">添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        roleName: '',
        status: null
      },
      // 状态选项
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
</style>