import API from "../../../api/index";

export default {
  // 获取角色列表
  getRoleList: params => {
    return API.requestGet({
      url: "role/getRoleList",
      params
    });
  },
  // 获取权限树状图
  getTree: params => {
    return API.requestGet({
      url: "jurisdiction/getTree",
      params
    });
  },
  // 新增角色
  add: params => {
    return API.requestPost({
      url: "role/addRole",
      data: params
    });
  },
  // 编辑角色
  edit: params => {
    return API.requestPost({
      url: "role/ediRole",
      data: params
    });
  },
  del: params => {
    return API.requestPost({
      url: "role/delRole",
      data: params
    });
  }
};
